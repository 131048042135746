import { Fragment, useState } from 'react'

type Props = {
  items: {
    title: string
    content: string
  }[]
}

export default function Accordion({ items }: Props) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const onHeaderClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  const renderedItems = items.map((item, index) => {
    const isActive = index === activeIndex ? 'active' : ''

    return (
      <Fragment key={index}>
        <p
          className={`accordion-header ${isActive}`}
          onClick={() => onHeaderClick(index)}
        >
          {item.title}
          <span>{isActive ? '-' : '+'}</span>
        </p>
        <div className={`accordion-content ${isActive}`}>
          <p>{item.content}</p>
        </div>
      </Fragment>
    )
  })

  return <div className="accordion">{renderedItems}</div>
}
