import Modal from 'react-modal'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export default function ModalRegister({ isOpen, onClose }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Pré venda!"
      overlayClassName={'react-modal-overlay'}
      className={'react-modal-content'}
    >
      <button className={'react-modal-close'} onClick={onClose}>
        <span>&times;</span>
      </button>
      <div className="terms">
        <h2>Termos de uso</h2>
        <strong>Descrição da empresa</strong>
        <p>
          A DANKI CODE AI LTDA, doravante referida como DANKI CODE AI, com sede
          em Servidão Francisco Monn, nº 48 Conjunto 01, Centro, Florianópolis,
          Santa Catarina CEP 88015-415, é uma empresa especializada no
          desenvolvimento e fornecimento de soluções avançadas de Inteligência
          Artificial para clientes em todo o mundo. Nosso compromisso é fornecer
          soluções eficazes de software e serviços de alta qualidade para os
          nossos clientes.
        </p>
        <strong>Termos e Condições</strong>
        <p>
          Este Termo refere-se a Danki Code AI LTDA. Ao navegar nos sites
          pertencentes a Danki Code AI LTDA, usar os serviços que são
          fornecidos, você AFIRMA que LEU, COMPREENDEU e CONCORDA com nossos
          Termos e Condições. Estes Termos e Condições abrangem todos os
          aplicativos, serviços de Internet ou extensões dos sites relacionados.
          Caso você não concorde ou não tenha ficado claro algum ponto,
          sugere-se que você NÃO NAVEGUE MAIS NELE até que você tenha sanado
          todas as suas dúvidas. Você poderá ainda, a qualquer momento, retornar
          ao site, clicar em termos de uso e reler quantas vezes desejar.
        </p>
        <p>
          1. Os Termos e Condições da Danki Code AI LTDA regem o uso dos sites
          de nossa propriedade e todo o seu conteúdo. Estes Termos descrevem as
          regras e regulamentos que orientam o uso dos produtos da Danki Code AI
          LTDA. Todos os materiais/informações/documentos/serviços ou todas as
          outras entidades (coletivamente referidas como "conteúdo") que
          aparecem no site https://www.dankicode.ai/ e demais sites pertencentes
          a Danki Code AI LTDA serão administrados de acordo com estes Termos e
          Condições. ATENÇÃO: Não continue a usar este site se você tiver
          qualquer objeção a qualquer um dos Termos e Condições declarados nesta
          página.
        </p>
        <p>
          2. O site é destinado a usuários com 18 (dezoito) anos de idade ou
          mais. Se você tem menos de 18 (dezoito) anos, não poderá usar ou
          registrar-se para usar este site ou seus serviços sem a permissão ou
          consentimento dos pais. Ao concordar com estes Termos e Condições,
          você tem a capacidade legal necessária para cumprir e ficar vinculado
          por estes Termos e Condições.
        </p>
        <p>
          3. Danki Code AI LTDA faz uso de cookies. Ao acessar nosso site, você
          concorda em usar cookies de acordo com nossa Política de Cookies.
          "Algum dos nossos parceiros de site podem usar cookies".
        </p>
        <p>
          4. Danki Code AI LTDA detêm os direitos de propriedade intelectual de
          todo o conteúdo. Todos os direitos de propriedade intelectual são
          reservados. Você pode acessar qualquer conteúdo do site para seu uso
          pessoal, sujeito às restrições definidas nestes termos e condições.
        </p>
        <p>
          Danki Code AI LTDA, por meio deste, determina que o usuário do site
          não cometa as seguintes ações:
        </p>
        <p>
          Reproduzir, republicar, duplicar ou copiar qualquer conteúdo do Danki
          Code AI LTDA;
        </p>
        <p>
          Vender, alugar, sublicenciar e/ou de outra forma comercializar
          qualquer conteúdo do Danki Code AI LTDA;
        </p>
        <p>
          Executar e/ou exibir publicamente qualquer conteúdo do Danki Code AI
          LTDA;
        </p>
        <p>
          Usar este site de forma que seja, ou talvez, danifique e/ou afete o
          acesso do usuário a este site;
        </p>
        <p>
          Usar este site contrário às regras, leis e regulamentos relevantes do
          seu país de residência, ou de uma maneira que cause, ou possa causar,
          danos ao site ou a qualquer pessoa ou entidade comercial;
        </p>
        <p>
          Realizar mineração de dados ou qualquer outra atividade semelhante
          relacionada a este site, ou durante o uso deste site;
        </p>
        <p>
          Usando este site para se envolver em qualquer forma de publicidade ou
          marketing empresarial.
        </p>
        <p>
          5. Áreas específicas deste site podem ser restritas ao acesso do
          usuário, e Danki Code AI LTDA pode estender ainda mais essa restrição
          a todo o site, a qualquer momento e a seu exclusivo critério. Qualquer
          identificação de usuário, chave de segurança ou senha que você possa
          ter neste site são confidenciais e você é responsável por manter a
          confidencialidade dessas informações
        </p>
        <p>
          6. Nós nos reservamos o direito de registrar solicitações para que
          você remova todos os links ou qualquer link específico criado por você
          que redirecione para o nosso site, e você aprova a remoção imediata
          desses links para o nosso site, mediante solicitação.
        </p>
        <p>
          7. Podemos alterar os termos e condições desses direitos de vinculação
          a qualquer momento. Ao conectar-se continuamente ao nosso site, você
          concorda em se comprometer e seguir os termos desta política de links
        </p>
        <p>
          8. Por favor, entre em contato conosco se encontrar algum link em
          nosso site que seja ofensivo, e poderemos considerar e analisar
          solicitações de remoção de tais links. E-mail suporte
          atendimento@dankicode.ai
        </p>
        <p>
          9. Os nossos sites podem conter links para sites ou aplicativos
          operados por terceiros. Não controlamos nenhum desses sites ou
          aplicativos de terceiros ou o operador de terceiros. Os nossos sites,
          objeto do presente Termos de Uso, não é responsável e não endossa
          quaisquer sites ou aplicativos de terceiros ou sua disponibilidade ou
          conteúdo.
        </p>
        <p>
          Danki Code AI LTDA não se responsabiliza pelos anúncios contidos no
          site. Você concorda em fazê-lo por sua própria conta e risco ao
          adquirir quaisquer bens e / ou serviços de terceiros. O anunciante é
          quem permanece responsável por tais bens e/ou serviços, e se você
          tiver alguma dúvida ou reclamação sobre eles, você deve entrar em
          contato com o anunciante.
        </p>
        <p>
          10. É importante salientar que o termo usado "Conteúdo do Usuário"
          significa qualquer áudio, vídeo, po, imagens ou outro material ou
          conteúdo que você decida exibir neste Site. Com relação ao conteúdo do
          usuário, ao exibi-lo, você concede ao Danki Code AI LTDA uma licença
          não exclusiva, mundial, irrevogável, isenta de royalties e
          sublicenciável para usar, reproduzir, adaptar, publicar, traduzir e
          distribuir em qualquer mídia.
        </p>
        <p>
          11. O Conteúdo do Usuário deve ser seu e não deve infringir os
          direitos de terceiros. Danki Code AI LTDA reserva-se o direito de
          remover qualquer parte do seu conteúdo deste site a qualquer momento,
          sem aviso prévio. A Danki Code AI LTDA tem permissão para monitorar
          suas atividades no site e remover qualquer conteúdo do usuário
          considerado impróprio, ofensivo, contrário às leis e regulamentos
          aplicáveis, ou que cause uma violação destes Termos e Condições.
        </p>
        <strong>Política de Privacidade</strong>
        <p>
          1. Ao acessar os nossos sites, informações específicas sobre o
          Usuário, como endereços de protocolo de Internet (IP), navegação no
          site, software do usuário e tempo de navegação, juntamente com outras
          informações semelhantes, serão armazenadas nos servidores de Danki
          Code AI LTDA. As informações sobre suas identidades, como nome,
          endereço, detalhes de contato, informações de faturamento e outras
          informações armazenadas neste site, serão estritamente usadas apenas
          para fins estatísticos e não serão publicadas para acesso geral. Danki
          Code AI LTDA, no entanto, não assume nenhuma responsabilidade pela
          segurança dessas informações.
        </p>
        <p>
          2. Os sites são fornecidos, com todas as responsabilidades e não
          assumem compromissos, representações ou garantias expressas ou
          implícitas de qualquer tipo relacionadas a estes sites ou ao conteúdo
          neles contido.
        </p>
        <strong>Formas de Pagamento e Indenização</strong>
        <p>
          1. Os pagamentos serão efetuados pelo cliente de acordo com o plano de
          assinatura escolhido e podem ser feitos via: cartão de crédito ou
          transferência bancária. Clientes Enterprise (plano exclusivo e
          personalizado) podem ter disponíveis outras opções de pagamento, além
          do cartão de crédito ou transferência bancária.
        </p>
        <p>
          2. O usuário concorda em indenizar os sites e suas afiliadas em toda a
          extensão, frente à todas as ações, reclamações, responsabilidades,
          perdas, danos, custos, demandas e despesas decorrentes do uso destes
          sites pelo Usuário, incluindo, sem limitação, qualquer reclamação
          relacionada à violação de qualquer uma das disposições destes Termos e
          Condições. Se estiver insatisfeito com algum ou todo o conteúdo destes
          sites ou qualquer um ou todos os seus Termos e Condições, o usuário
          pode interromper o uso destes sites.
        </p>
        <p>
          3. Em qualquer momento, os usuários podem interromper o uso dos sites
          para isso, nos sites, estão disponíveis as orientações necessárias.
          Caso ainda fique alguma dúvida, não hesite em enviar um e-mail para
          atendimento@dankicode.ai
        </p>
        <p>
          4. Nós nos reservamos o direito e critério exclusivo de, e sem aviso
          prévio ou responsabilidade, negar o acesso e uso dos sites (incluindo
          o bloqueio de endereços IP específicos) a qualquer usuário por
          qualquer motivo, incluindo, mas não se limitando à violação de
          qualquer representação, garantia ou acordo nestes Termos ou em
          qualquer lei ou regulamento aplicável.
        </p>
        <strong>Entrega e envio do Produto</strong>
        <p>
          1. Como o produto é um software como serviço, não há envio físico.
          Após a confirmação do pagamento, o cliente receberá as credenciais de
          acesso ao serviço por e-mail.
        </p>
        <strong>Troca e Devolução</strong>
        <p>
          1. A DANKI CODE AI não oferece reembolsos. O cliente pode cancelar a
          assinatura a qualquer momento, mas não terá direito a um reembolso por
          pagamentos já efetuados. Art. 49. O consumidor pode desistir do
          contrato, no prazo de 7 dias a contar de sua assinatura ou do ato de
          recebimento do produto ou serviço, sempre que a contratação de
          fornecimento de produtos e serviços ocorrer fora do estabelecimento
          comercial, especialmente por telefone ou a domicílio. Parágrafo único.
          Se o consumidor exercitar o direito de arrependimento previsto neste
          artigo, os valores eventualmente pagos, a qualquer título, durante o
          prazo de reflexão, serão devolvidos, de imediato, monetariamente
          atualizados.
        </p>
        <p>
          2. Os Termos e Condições destes sites serão regidos e interpretados de
          acordo com as leis do país ou estado em que os sites operam. Você, por
          meio deste, se submete incondicionalmente à jurisdição não exclusiva
          dos tribunais localizados no Brasil para a resolução de quaisquer
          disputas.
        </p>
        <strong> Disposições gerais</strong>
        <p>
          1. Estes sites reservam-se o direito de revisar estes Termos a
          qualquer momento conforme julgar adequado. Por isso é fundamental que
          os seus usuários estejam atentos a essas alterações.
        </p>
        <p>
          2. Os sites reservam-se o direito de ceder, transferir e subcontratar
          seus direitos e/ou obrigações sob este Acordo sem qualquer notificação
          ou consentimento prévio necessário. Os usuários não terão permissão
          para atribuir, transferir ou subcontratar qualquer um de seus direitos
          e/ou obrigações sob estes Termos. Além disso, uma pessoa que não seja
          parte destes Termos e Condições não terá o direito de fazer cumprir
          qualquer disposição neles contida.
        </p>
        <p>
          3. Estes Termos e Condições, incluindo quaisquer avisos legais e
          isenções de responsabilidade nestes sites, constituem o acordo
          completo entre os sites e você em relação ao uso destes sites. Em
          última análise, este Acordo substitui todos os acordos e entendimentos
          anteriores relativos ao mesmo.
        </p>
        <p>
          5. Qualquer dúvida, entre em contato por meio do endereço de e-mail:
          atendimento@dankicode.ai
        </p>
        <strong>Do foro</strong>
        <p>
          Este contrato será regido e interpretado de acordo com as leis
          brasileiras. Qualquer litígio decorrente deste contrato será submetido
          ao foro da comarca de Florianópolis, estado de Santa Catarina.
        </p>
      </div>
    </Modal>
  )
}
